<template>
    <div class="container-wrapper">
        <div class="container">
            <main>
                <h1>Summary</h1>

                <h2>My Chosen Upgrade</h2>
                <data-table :data-to-display="dataToDisplay" />

                <h2>Declarations</h2>
                <div class="content-block">
                    <ol v-if="getUpgrade" type="1">
                        <li>
                            My answers to the Declaration Questions haven't changed since buying my policy.<br>
                            <a class="link" @click="showModal = true">Review original answers.</a>
                        </li>
                        <li>{{ illnessCoverDeclaration }}</li>
                        <li>
                            I don't have any claims pending and to the best of my knowledge there are no circumstances
                            giving rise or likely giving rise to a claim under this policy
                        </li>
                    </ol>
                    <check-box v-model="confirmDeclarations" identifier="declarationsCheckbox">
                        I confirm that I have read and understood the above statements.
                    </check-box>
                    <check-box v-model="confirmPayment" identifier="paymentCheckbox">
                        I confirm that I'm happy to upgrade my policy using the payment details on my account as stated below.
                    </check-box>
                    <check-box v-model="confirmDocuments" identifier="documentsCheckbox">
                        I confirm that I have read and understood the <a class="link" @click.prevent="toggleDocuments">Pre-contractual documentation</a>
                    </check-box>
                    <check-box v-model="confirmNeeds" identifier="needsCheckbox">
                        {{ confirmNeedsDeclaration }}
                    </check-box>

                    <document-list v-if="showDocuments" :documents="getUpgradeDocuments" />

                    <div v-if="getPaymentDetails" class="payment-details">
                        <div class="payment-details__account">
                            <p><strong>Payment Details</strong></p>
                            <p>{{ getPaymentDetails.text }} ending in {{ getPaymentDetails.lastFourDigits }}</p>
                        </div>
                        <p>If you wish to change your payment details, just call <strong>03300 244 007</strong> and one of our team will help you out!</p>
                    </div>
                </div>

                <tie-alert v-if="error && error.length > 0">
                    {{ error }}
                </tie-alert>

                <div class="btn-container">
                    <tie-button variation="btn-login" :disabled="!canUpgrade" @click="upgradePolicy">
                        Confirm Upgrade
                    </tie-button>
                </div>

                <Teleport to="body">
                    <tie-modal :show="showModal" :center="false" @close="showModal = false">
                        <template #header>
                            Declarations
                        </template>
                        <template #body>
                            <div v-if="getDeclarationQuestions" class="declarations">
                                <div
                                    v-for="questions in getDeclarationQuestions"
                                    :key="questions.group"
                                    class="declaration-group "
                                    :class="[questions.group, questions.declarations[0].key]"
                                >
                                    <h4 />
                                    <p v-if="questions.text">
                                        <strong>{{ questions.text }}</strong>
                                    </p>
                                    <div
                                        v-for="declaration in questions.declarations"
                                        :key="declaration.key"
                                        class="declaration-question"
                                        :class="declaration.key"
                                    >
                                        <div>
                                            <span class="declaration-title" :class="!questions.text ? 'bold' : ''">{{ declaration.text }}</span>
                                            <br>
                                            <span class="declaration-answer " :class="declaration.response">Your Answer: </span>
                                            <div v-if="declaration.comment">
                                                <br>
                                                <strong>Your Comment:</strong> {{ declaration.comment }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </tie-modal>
                </Teleport>
            </main>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '../../../../../../components/policies/PolicyDetails/DataTable';
import TieButton from '../../../../../../components/tie-button/TieButton';
import TieAlert from '../../../../../../components/tie-alert/TieAlert';
import CheckBox from '../../../../../../components/check-box/CheckBox';
import TieModal from '../../../../../../components/tie-modal/TieModal';
import DocumentList from '../../../../../../components/policies/PolicyDetails/DocumentList';

export default {
    name: 'MyPolicyUpgradeSummary',

    components: {
        DataTable,
        TieButton,
        CheckBox,
        TieModal,
        TieAlert,
        DocumentList
    },

    data() {
        return {
            confirmDeclarations: false,
            confirmPayment: false,
            confirmDocuments: false,
            confirmNeeds: false,
            showDocuments: false,
            showModal: false,
            error: null
        };
    },

    computed: {
        ...mapGetters([
            'getUpgrade'
        ]),

        canUpgrade() {
            return (this.confirmDeclarations && this.confirmPayment && this.confirmDocuments && this.confirmNeeds);
        },

        getPolicy() {
            return this.$store.getters.getPolicy(this.$route.params.id);
        },

        getPaymentDetails() {
            let upgradeOption = this.getUpgrade.data;

            if (!upgradeOption || !('attributes' in upgradeOption)) {
                return null;
            }

            return {
                text: upgradeOption.attributes.payment.method === 'D' ? 'Account' : upgradeOption.attributes.payment.card.type,
                lastFourDigits: upgradeOption.attributes.payment.method === 'D' ? upgradeOption.attributes.payment.bank['account_name'] : upgradeOption.attributes.payment.card.last4
            };
        },

        getDeclarationQuestions() {
            if (!this.getUpgrade) {
                return [];
            }

            let upgradeOptionIncluded = this.getUpgrade.included[0];

            if (!upgradeOptionIncluded || !('attributes' in upgradeOptionIncluded)) {
                return [];
            }

            return upgradeOptionIncluded.attributes.questions;
        },

        getUpgradeDocuments() {
            let upgradeOption = this.getUpgrade.data;

            if (!upgradeOption || !('attributes' in upgradeOption)) {
                return [];
            }

            return [
                {
                    name: 'Product Information',
                    api: false,
                    link: 'https://s3.eu-west-2.amazonaws.com/tie-core/documents/pet/' + upgradeOption.attributes['policy-type-group'] + '/ipid.pdf'
                },
                {
                    name: 'Policy Wording',
                    api: false,
                    link: 'https://s3.eu-west-2.amazonaws.com/tie-core/documents/pet/' + upgradeOption.attributes['policy-type-group'] + '/policy-wording.pdf'
                }
            ];
        },

        isAnnual() {
            return this.getPolicy.attributes['payment-frequency'] === 'A';
        },

        getPaymentFrequency() {
            let paymentFrequency = this.getPolicy.attributes['payment-frequency'];
            switch (paymentFrequency) {
                case 'A':
                    return 'Annual';
                case 'L':
                    return 'Lunar Monthly';
                case 'M':
                    return 'Monthly';
                default:
                    return null;
            }
        },

        dataToDisplay() {
            let upgradeOption = this.getUpgrade.data;

            if (!upgradeOption || !this.getPolicy) {
                return [];
            } else {
                let data = [
                    {
                        title: 'Pet Name',
                        value: this.getPolicy.attributes.insured
                    },
                    {
                        title: 'Policy Type',
                        value: upgradeOption.attributes.title
                    },
                    {
                        title: 'Policy Term',
                        value: this.getPaymentFrequency
                    },
                    {
                        title: 'New Premium',
                        value: `£${ upgradeOption.attributes.premium }`
                    },
                    {
                        title: 'Upgrade Date',
                        value: new Date().toLocaleDateString('en-GB')
                    },
                    {
                        title: ['timelimited', 'maxbenefit', 'lifetime'].indexOf(upgradeOption.attributes['policy-type-group']) > -1 ? 'Your Upgraded Cover for Illness Starts' : 'Your Cover for Illness Starts',
                        value: new Date(upgradeOption.attributes['illness-cover-start-date']).toLocaleDateString('en-GB')
                    },
                ];

                if (!this.isAnnual) {
                    data.push({
                        title: 'Next Collection',
                        value: new Date(upgradeOption.attributes['next-payment-date']).toLocaleDateString('en-GB')
                    });
                }

                return data;
            }
        },

        illnessCoverDeclaration() {
            if (!this.getPolicy || !('attributes' in this.getPolicy)) {
                return null;
            }

            let currentPolicyType = this.getPolicy.attributes['policy-type-group'];
            if(['TIMELIMITED', 'MAXBENEFIT', 'LIFETIME'].indexOf(currentPolicyType) > -1) {
                return 'I understand that any cover I have for illness will continue until my Upgraded Cover for Illness Starts.';
            }
            return 'I understand that costs for any illness displaying clinical signs (changes in my pet’s normal healthy state, condition, appearance, bodily functions or behaviour) within 14 days of the Upgrade Date will not be covered.';
        },

        confirmNeedsDeclaration() {
            let upgradeOption = this.getUpgrade.data;

            if (!upgradeOption || !('attributes' in upgradeOption)) {
                return null;
            }

            let declaration;
            switch(upgradeOption.attributes['policy-type-group']) {
                case 'timelimited':
                    declaration = 'I confirm this product meets my demands and needs by providing cover for my pet for veterinary treatment for up to 365 days from the date each injury, illness or condition first showed clinical signs.';
                    break;
                case 'maxbenefit':
                    declaration = 'I confirm this product meets my demands and needs by providing vet’s fees cover for each injury, illness or condition to the maximum amount available irrespective of the time taken to reach it.';
                    break;
                case 'accidentonly':
                    declaration = 'I confirm this product meets my demands and needs by providing cover for veterinary treatment of my pet following an injury caused by an accident.';
                    break;
                case 'lifetime':
                    declaration = 'I confirm this product meets my demands and needs by providing vet’s fees cover throughout the life of my pet.';
                    break;
                case 'publicliability':
                    declaration = 'I confirm this product meets my demands and needs by providing cover for my legal liability to others for compensation and costs arising out of my ownership of my dog.';
                    break;
                default:
                    break;
            }
            return declaration;
        }
    },

    mounted() {
        this.$store.dispatch('getPolicy', this.$route.params.id);
        this.$store.dispatch('getPolicyUpgrade', this.$route.params.upgradeid);
    },

    methods: {
        upgradePolicy() {
            this.$store.dispatch('patchPolicyUpgrade', this.$route.params.upgradeid).then(() => {
                this.$router.push(`/dashboard/my-policies/${ this.$route.params.id }/upgrade/${ this.$route.params.upgradeid }/confirmation`);

            })
                .catch((resp) => {
                    console.log(resp);
                    if (resp && resp.errors) {
                        this.error = resp.errors[0].title;
                    }
                });
        },
        toggleDocuments() {
            this.showDocuments = !this.showDocuments;
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../../../../../../assets/styles/variables";
@import "../../../../../../assets/styles/container";
@import "../../../../../../assets/styles/policy-upgrade";

ol {
    li {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
</style>